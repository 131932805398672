<template>
  <backoffice-container>
    <b-card v-if="role">
      <!-- Unshare -->
      <div class="trash-icon">
        <feather-icon
          v-if="!role.isGlobal"
          v-b-tooltip.hover.bottom
          icon="Trash2Icon"
          :title="$t('unshare.tooltip')"
          size="20"
          class="text-primary pointer share-icon"
          @click="isUnshareModalVisible = true"
        />
      </div>
      <div class="d-flex flex-wrap justify-content-between mt-2">
        <h4>{{ $t("backoffice.roles.role-detail") }}</h4>
        <b-button
          v-if="!role.isGlobal"
          variant="primary"
          :disabled="isDisabled"
          @click="onSubmit()"
        >
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
      <!-- Body -->
      <validation-observer>
        <!-- Form -->
        <b-form>
          <!-- Name -->
          <b-row>
            <b-col>
              <b-form-group label-for="message">
                <label
                  class="font-weight-bold"
                >{{ $t("backoffice.roles.name")
                }}<span v-if="!role.isGlobal">*</span></label>
                <b-form-input
                  id="message"
                  v-model="role2.name"
                  name=" "
                  :placeholder="$t('form.type-placeholder')"
                  rows="8"
                  :plaintext="role.isGlobal"
                  rules="required"
                  :state="isValidName"
                  @input="validator()"
                />
                <small v-if="isValidName === false" class="text-danger">
                  {{ $t("backoffice.roles.validator-message") }}
                </small>
              </b-form-group>
              <!-- Tagname -->
              <b-form-group label-for="message">
                <label class="font-weight-bold">{{
                  $t("backoffice.roles.label")
                }}</label>
                <b-form-input
                  id="message"
                  v-model="role2.tagName"
                  name=" "
                  :placeholder="$t('form.type-placeholder')"
                  rows="8"
                  rules="required"
                  :plaintext="role.isGlobal"
                  :state="isValidTag"
                  @input="validator()"
                />
                <small v-if="isValidTag === false" class="text-danger">
                  {{ $t("backoffice.roles.validator-label") }}
                </small>
              </b-form-group>
              <p class="mb-50 font-weight-bold">
                Namespace
              </p>
              <p>
                {{
                  role.namespace === "backoffice"
                    ? $t("backoffice.roles.backoffice")
                    : $t("backoffice.roles.community")
                }}
              </p>
              <p class="mb-50 font-weight-bold">
                {{ $t("backoffice.analytics.data-type") }}
              </p>
              <b-badge
                :variant="role.isGlobal ? 'light-primary' : 'light-info'"
                class="mb-1"
              >
                {{
                  role.isGlobal
                    ? $t("backoffice.roles.global-role")
                    : $t("backoffice.roles.custom")
                }}
              </b-badge>
              <!-- <p class="mb-50 font-weight-bold">
                {{ $t("backoffice.roles.default-role") }}
              </p>
              <b-checkbox switch v-model="isDefaultRole" @change="handleDefault"/> -->
            </b-col>
            <b-col cols="12" lg="6">
              <!-- Description -->
              <b-form-group label-for="description">
                <label class="font-weight-bold">
                  {{ $t("backoffice.roles.description") }}
                </label>
                <b-form-textarea
                  v-if="!role.isGlobal"
                  id="description"
                  v-model="roleDescription"
                  name=" "
                  :placeholder="
                    role.isGlobal ? '--' : $t('form.type-placeholder')
                  "
                  rows="8"
                  rules="required"
                />
                <p v-else>
                  {{ getRoleDescription(role) || "--" }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- Permissions -->
    <b-card v-if="!isLoading && role">
      <div class="d-flex flex-wrap justify-content-between mt-2">
        <div>
          <h4>{{ $t("backoffice.roles.permissions.title") }}</h4>
          <p v-if="role.isGlobal">
            {{ $t("backoffice.roles.permissions.global-message") }}
          </p>
        </div>
        <b-button
          v-if="!role.isGlobal"
          variant="primary"
          @click="savePermissions()"
        >
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
      <b-row class="mt-1">
        <b-col
          v-for="[group, permissions] in Object.entries(permissionsGroups)"
          :key="group"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <b-card>
            <!-- Card/group name -->
            <h4 class="model-type">
              {{ group + ' (' + permissions.length + ')' }}
            </h4>
            <!-- Permissions list -->
            <span
              v-for="(permission, index) in role2.permissions"
              :key="index"
              class="d-flex font-weight-bold"
            >
              <span
                v-if="permission.group === group"
                class="d-flex mb-50"
              >
                <b-form-checkbox
                  v-if="!role.isGlobal"
                  v-model="role2.permissions[index].hasPermissions"
                  :disabled="role.isGlobal"
                  switch
                />
                <span
                  :class="
                    role2.permissions[index].hasPermissions || role.isGlobal
                      ? 'text-primary'
                      : 'text-dark'
                  "
                >
                  {{ permission.name }}
                </span>
              </span>
            </span>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else>
      <b-spinner type="grow" small class="text-primary d-block m-auto" />
    </b-card>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="role"
      @remove-item="handleRemoveItem"
    />
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { MainType, MainTypeTranslated } from '@copernicsw/community-common';

export default {
  name: 'RoleDetail',
  components: {
    ValidationObserver,
    BackofficeContainer,
    UnshareModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      role2: {
        name: null,
        tagName: null,
        description: {},
        permissions: [],
      },
      required,
      existSomeName: false,
      existSomeTag: false,
      isValidTag: null,
      isValidName: null,
      isUnshareModalVisible: false,
      isLoading: true,
      questionSubtypes: [],
      isDefaultRole: false,
      deleteDefaultRole: false,
      votesSubtypes: [],
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return this.role2.name === '' || this.tagName === '';
      // || !this.role2.description;
    },
    role() {
      const roleKey = this.$route.params.id;
      console.log('roleKey', roleKey);
      const currentRole = this.$store.getters.roles?.unpaginated.filter(
        ({ key }) => key === this.$route.params.id,
      )[0];
      console.log('currentRole', currentRole)
      return currentRole && currentRole.key === roleKey ? currentRole : null;
    },
    total() {
      return this.$store.getters.roles?.meta?.total;
    },
    fields() {
      return [
        {
          key: 'fullname',
          label: this.$t('backoffice.roles.name'),
        },
        {
          key: 'tagName',
          label: this.$t('backoffice.roles.label'),
        },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    roleDescription() {
      //TODO: review if we need to change by role2
      return this.role.description && this.role.description.length > 0 ? this.role.description[this.locale] : this.role.description;
    },
    roleTypes() {
      // console.log('this.allPermissions', this.allPermissions)
      const duplicatedModelTypes = this.allPermissions
        ?.map((role) => role.modelType)
        .reverse();
      const ModelTypes = new Set(duplicatedModelTypes);
      return [...ModelTypes];
    },
    allPermissions() {
      return this.$store.getters.permissions?.unpaginated?.reduce(
        (acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        },
        [],
      );
    },
    permissions() {
      return this.$store.getters.permissions?.unpaginated;
    },
    roles() {
      return this.$store.getters.roles.unpaginated;
    },
    enabledApps() {
      // return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).map(item=> item.nameSingular);
      return this.$store.getters.installedApps.data?.map(
        (item) => item.addonSingular,
      );
    },
    permissionsGroups() {
      let groups = [];
      this.permissions.map(permission => {
        if(!groups[permission.group] || groups[permission.group].length <= 0){
          groups[permission.group] = [permission];
        }else{
          groups[permission.group].push(permission);
        }
      });
      // console.log('groups', groups);
      return groups;
    },
    enablePermissions() {
      return this.roleTypes?.filter((item) => {
        // console.log('item', item)
        // Backoffice:
        if (this.role.namespace == 'backoffice') {
          return item;
          // return item === 'apps' || item === 'members' || item === 'roles' || item === 'settings' || item === 'community';
        }
        // Community:
        return this.enabledApps?.includes(item);
      });
    },
  },
  watch: {
    allPermissions() {
      this.initialize();
    },
  },
  async created() {
    await this.getRoles();
    await this.getInstalledApps();
    this.isLoading = true;
    await this.getPermissions();
    this.isDefaultRole = this.currentCollective?.defaultRole?.key === this.role.key;
    // console.log('this.allPermissions', this.allPermissions)
    // console.log('this.enablePermissions', this.enablePermissions)
    // console.log('this.permissionsGroups', this.permissionsGroups)
    // this.permissionsGroups.forEach((key, value) => {
    //   console.log('key:', key)
    //   console.log('value:', value)
    // });
    // console.log('Object.entries(this.permissionsGroups)', Object.entries(this.permissionsGroups))
  },
  methods: {
    async handleDefault() {
      if (!this.isDefaultRole) {
        this.deleteDefaultRole = true;
      }
      try {
        await this.$store.dispatch('editCommunity', {
          settings: {
            defaultRoleKey: this.role.key,
            ...(this.deleteDefaultRole ? { deleteDefaultRole: true } : ''),
          },
          forceUpdate: true,
        });
        this.notifySuccess(this.$t('backoffice.roles.messages.edit-success'));
      } catch {
        this.notifyError(this.$t('backoffice.roles.messages.edit-error'));
      }
    },
    initialize() {
      this.role2 = {
        name: this.role.name,
        tagName: this.role.tagName,
        description: this.role.description ? this.role.description : {},
        permissions: this.role.permissions,
      };
      const roles = this.role2.permissions.map((item) => {
        if (item.submodelType !== null) {
          //
        }
        item.hasPermissions = true;
        return item;
      });
      let todos = [];
      if (this.role.isGlobal) {
        todos = this.allPermissions?.filter((item) => {
          if (!this.role2.permissions.includes(item)) {
            item.hasPermissions = false;
          }
          return item;
        });
      } else {
        todos = this.allPermissions?.filter((item) => {
          if (!this.role2.permissions.includes(item)) {
            item.hasPermissions = false;
          }
          return item;
        });
      }

      const ids = new Set(this.role.permissions.map((d) => d.key));
      this.role2.permissions = [
        ...roles,
        ...todos.filter((d) => !ids.has(d.key)),
      ];
      this.questionSubtypes = [];
      this.role2.permissions.map((item) => {
        if (item.submodelType === 'answer' && item.modelType === 'question') {
          this.questionSubtypes.push(item);
        }
      });
      this.votesSubtypes = [];
      this.role2.permissions.map((item) => {
        if (item.submodelType === 'answer' && item.modelType === 'vote') {
          this.votesSubtypes.push(item);
        }
      });
      this.updateBreadcrumbs();
    },
    async getRoles() {
      await this.$store.dispatch('getRoles', {
        communitySlug: this.$store.getters.currentCollective.slug,
        page: this.$route.params.page,
        perPage: 15,
        namespace: 'backoffice'
      });
      this.initialize();
    },
    validator() {
      const existSomeName = this.roles?.filter(
        (item) => item.name === this.role2.name && this.role2.name !== this.role.name,
      );
      existSomeName.length > 0
        ? (this.existName = true)
        : (this.existName = false);
      this.isValidName = this.role2.name !== '' && !this.existName;
      this.updateBreadcrumbs();
      const existSomeTag = this.roles?.filter(
        (item) => item.tagName === this.role2.tagName
          && this.role.tagName !== this.role2.tagName,
      );
      existSomeTag.length > 0
        ? (this.existTag = true)
        : (this.existTag = false);
      this.isValidTag = !this.existTag;
    },
    async savePermissions() {
      const permissions = [];
      console.log('this.role.permissions', this.role.permissions);
      this.role2.permissions
        .filter((item) => item.hasPermissions)
        .map((item) => permissions.push({ key: item.key }));
      const noPermissions = [];
      this.role2.permissions
        .filter(
          (item) => !item.hasPermissions && this.role.permissions.includes(item),
        )
        .map((item) => noPermissions.push({ key: item.key }));
      try {
        await this.$store.dispatch('attachRolePermissions', {
          roleKey: this.role.key,
          permissions,
        });
        await this.$store.dispatch('dettachRolePermissions', {
          roleKey: this.role.key,
          permissions: noPermissions,
        });
        this.notifySuccess(
          this.$t('backoffice.roles.messages.permissions-success'),
        );
        this.page = 1;
        const response = await this.getRoles();
        this.initialize();
      } catch {
        this.notifyError(
          this.$t('backoffice.roles.messages.permissions-error'),
        );
      }
    },
    updateBreadcrumbs() {
      let breadcrumbs = [];
      // console.log('this.role', this.role);
      if(this.role.namespace === 'community'){
        breadcrumbs.push({
          text: 'backoffice.roles.community-roles',
          to: { name: 'roles-community' },
        });
      } else if(this.role.namespace === 'backoffice'){
        breadcrumbs.push({
          text: 'backoffice.roles.backoffice-roles',
          to: { name: 'staff-roles' },
        });
      }
      breadcrumbs.push(
        // {
        //   text: 'backoffice.roles.subtitle',
        //   to: { name: 'roles' },
        // },
        {
          text: this.role2.name
            ? this.role2.name
            : 'backoffice.roles.role-detail',
          active: true,
        });
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    getPermissionTranslatedName(permission) {
      // TODO: Create constant js
      const transaltedAction = {
        index: this.$t('backoffice.roles.permissions.index'),
        view: this.$t('backoffice.roles.permissions.view'),
        create: this.$t('backoffice.roles.permissions.create'),
        update: this.$t('backoffice.roles.permissions.update'),
        delete: this.$t('backoffice.roles.permissions.delete'),
        invite: this.$t('backoffice.roles.permissions.invite'),
        share: this.$t('backoffice.roles.permissions.share'),
        manage: this.$t('backoffice.roles.permissions.manage'),
        publish: this.$t('backoffice.roles.permissions.publish'),
        export: this.$t('backoffice.roles.permissions.export'),
        'update customization': this.$t(
          'backoffice.roles.permissions.update-customization',
        ),
        'index payments': this.$t(
          'backoffice.roles.permissions.index-payments',
        ),
        'export metrics': this.$t(
          'backoffice.roles.permissions.export-metrics',
        ),
        'index members': this.$t('backoffice.roles.permissions.index'),
        'view members': this.$t('backoffice.roles.permissions.view'),
        'index registered members': this.$t(
          'backoffice.roles.permissions.index-registered-members',
        ),
        'index members requests': this.$t(
          'backoffice.roles.permissions.index-members-requests',
        ),
        'manage members': this.$t('backoffice.roles.permissions.manage'),
        'manage members requests': this.$t(
          'backoffice.roles.permissions.manage-members-requests',
        ),
        'invite new members': this.$t(
          'backoffice.roles.permissions.invite-new-members',
        ),
        'create members': this.$t('backoffice.roles.permissions.create'),
        'update members': this.$t('backoffice.roles.permissions.update'),
        'delete members': this.$t('backoffice.roles.permissions.delete'),
        'invite members': this.$t('backoffice.roles.permissions.invite'),
        'index forms': this.$t('backoffice.roles.permissions.index'),
        'manage forms': this.$t('backoffice.roles.permissions.manage'),
        manage: this.$t('backoffice.roles.permissions.manage'),
        'export forms data': this.$t('backoffice.roles.permissions.export'),
        'index roles': this.$t('backoffice.roles.permissions.index'),
        'manage roles': this.$t('backoffice.roles.permissions.manage'),
        'index exams': this.$t('backoffice.roles.permissions.index'),
        'manage exams': this.$t('backoffice.roles.permissions.manage'),
        'export exams data': this.$t('backoffice.roles.permissions.export'),
      };
      return transaltedAction[permission];
    },
    roleTranslation(rol) {
      // console.log('roleTranslation rol:', rol);
      let namePermission = '';
      if (this.role.namespace == 'backoffice') {
        const roleBO = {
          apps: 'Apps',
          settings: this.$t('backoffice.settings.title'),
          members: this.$t('members.title'),
          roles: this.$t('backoffice.roles.title'),
          community: MainTypeTranslated[MainType[this.currentCollective.mainType]][this.locale],
        };
        namePermission = roleBO[rol];
      } else {
        this.$store.getters.installedApps.data.map((item) => {
          if (item.addonSingular === rol) {
            namePermission = item.addon[this.locale];
          }
        });
      }
      return namePermission;
    },
    async onSubmit() {
      try {
        await this.$store.dispatch('editRoles', {
          roleKey: this.role.key,
          name: this.role2.name,
          description: [this.role2.description],
          tagName: this.role2.tagName || this.role2.name.toLowerCase(),
        });
        this.role2.tagName = this.role2.tagName || this.role2.name.toLowerCase();
        this.notifySuccess(this.$t('backoffice.roles.messages.edit-success'));
      } catch {
        this.notifyError(this.$t('backoffice.roles.messages.edit-error'));
      }
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'roles/delete',
            requestConfig: {
              roleKey: this.role.key,
            },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.role'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'roles',
            params: { communityId: this.$route.params.communityId },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.role'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    async getPermissions() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'permissions',
        forceAPICall: true,
        requestConfig: {
          namespace: this.role?.namespace,
        },
      });
      this.isLoading = false;
    },
    getRoleDescription(role) {
      return JSON.stringify(role?.description);
    }
  },
};
</script>
<style lang="scss" scoped>
.subtype-text {
  font-weight: 500;
}
.model-type h4:first-letter {
  text-transform: uppercase;
}
.share-icon {
  position: absolute;
  margin-top: -60px;
  right: 0;
  cursor: pointer;
}
</style>
